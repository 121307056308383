@import "bootstrap/dist/css/bootstrap.min.css";
.App {
  text-align: center;
  background-color: #20315a;
}
.h1 {
  font-family: "DM Serif Display", serif;
  color: #ffd972;
  padding-top: 25px;
  font-style: "italic";
}

.page-link {
  position: relative;
  display: block;
  padding: var(--bs-pagination-padding-y) var(--bs-pagination-padding-x);
  font-size: var(--bs-pagination-font-size);
  color: #ffd972;
  text-decoration: none;
  background-color: #20315a;
  border: var(--bs-pagination-border-width) solid #20315a;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
[role="button"] {
  cursor: pointer;
}
.page-link {
  position: relative;
  display: block;
  padding: var(--bs-pagination-padding-y) var(--bs-pagination-padding-x);
  font-size: var(--bs-pagination-font-size);
  color: var(#20315a);
  text-decoration: none;
  background-color: #20315a;

  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.pagination {
  --bs-pagination-active-bg: #20315a;
  --bs-pagination-active-border-color:none;
  --bs-pagination-hover-bg: #20315a;
  --bs-pagination-hover-bg: #20315a;
  --bs-pagination-hover-color: #ffd972;
  --bs-pagination-hover-border-color: #20315a;
  --bs-pagination-focus-bg: none;
  --bs-pagination-active-border-color: #20315a;
  --bs-pagination-color: none;
  --bs-pagination-focus-color: #ffffff;
  --bs-pagination-active-border-color: #20315a;
  --bs-pagination-color: none;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #000000;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
